<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('pos_devices.pos_devices') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('pos_devices.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_devices.store') }}<span class="text-danger">*</span></label>
                <!--                            <select name="store" id="store" v-model="data.store_id" class="custom-select">-->
                <!--                                <option v-for="row in stores" :value="row.id" :key="row.id">{{row.name}}</option>-->
                <!--                            </select>-->
                <multiselect v-model="store"
                             :placeholder="$t('pos_devices.store')"
                             :class="validation && validation.store_id ? 'is-invalid' : ''"
                             label="name"
                             track-by="id"
                             :options="stores"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.store_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.store_id[0] }}
                            </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_session.treasury') }}<span class="text-danger">*</span></label>
                <multiselect v-model="treasury"
                             :class="validation && validation.cash_treasury_id ? 'is-invalid' : ''"
                             :placeholder="$t('pos_session.treasury')"
                             label="name"
                             track-by="id"
                             :options="treasuries"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.treasury_id" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.treasury_id[0] }}
                        </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.status" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('pos_devices.image') }}</label>
                  <upload-file
                      @file="listenerAttachment"
                      :inner-id="'image'"
                      :placeholder="$t('pos_devices.upload_image')"
                      :upload="dir_upload"
                      :start-link="'base'"
                      v-bind:valueprop="{name:'attachment',path:img_url}" v-if="reloadUploadAttachment">
                  </upload-file>
                  <span v-if="validation && validation.attachment" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.attachment[0] }}
                            </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('pos_devices.description') }}</label>
                  <textarea type="text" v-model="data.description" class="form-control" :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.description[0] }}
                            </span>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-pos-devices",
  data() {
    return {
      mainRoute: 'pos/pos-devices',
      mainRouteDependency: 'base/dependency',
      dir_upload: 'pos_devices',
      // categories: [],
      stores: [
        // {id: 1, name: 'Store 1'},
        // {id: 2, name: 'Store 2'},
        // {id: 3, name: 'Store 3'},
        // {id: 4, name: 'Store 4'},
      ],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        name: null,
        store_id: null,
        parent_category: null,
        img: null,
        description: null,
        status: true,
        cash_treasury_id: null,
      },
      isEditing: false,
      validation: null,

      img_url: null,
      store: null,
      reloadUploadAttachment: true,
      treasury: [],
      treasuries: [],
    };
  },
  watch: {
    'data.name': function (val) {
      if (val) {
        this.data.name = val.slice(0, 254);
      }
    },
    store: function (val) {
      if (val) {
        this.data.store_id = val.id;
      } else {
        this.data.store_id = null;
      }
    },
    treasury: function (val) {
      if (val) {
        this.data.cash_treasury_id = val.id;
      } else {
        this.data.cash_treasury_id = null;
      }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/pos/pos-devices');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/pos/pos-devices');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.store_id = response.data.data.store_id;
        this.data.parent_category = response.data.data.parent_category;
        this.data.img = response.data.data.img;
        this.img_url = response.data.data.img_url;
        this.data.description = response.data.data.description;
        this.data.status = response.data.data.status;
        this.store = response.data.data.store;
        this.data.cash_treasury_id = response.data.data.cash_treasury_id;
        this.treasury = response.data.data.cash_treasury;
        this.reloadUploadAttachment = true;
      });
    },

    // getCategories() {
    //   ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
    //     this.categories = response.data.data;
    //   });
    // },
    getStores() {
      ApiService.get(this.mainRouteDependency + "/pos-stores-list").then((response) => {
        this.stores = response.data.data;
      });
    },
    getTreasuries() {
      ApiService.get(this.mainRouteDependency + "/treasuries").then((response) => {
        this.treasuries = response.data.data;
      });
    },
    loadOptions() {
    },
    updateValue(value) {
      this.data.parent_category = value
    },
    listenerAttachment(event) {
      if (event) {
        this.img_url = event.pathDB;
        this.data.img = event.name;
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.pos_devices"), route: '/pos/pos-devices'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    // this.getCategories();
    this.getStores();
    this.getTreasuries();

    if (this.idEdit) {
      this.getData();
    }

  },
};
</script>


